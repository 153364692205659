<template>
  <div class="home">
 

    <PostFeed/>  <SideBar />
    <ToolBar/>
  </div>
</template>

<script>
// @ is an alias to /src
import ToolBar from '@/components/ToolBar.vue'
import SideBar from '@/components/SideBar.vue'
import PostFeed from  '@/components/PostFeed.vue'

export default {
  name: 'PostView',
  components: {
    ToolBar,
    SideBar,
    PostFeed
  }
}
</script>
