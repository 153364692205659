<template>
  <div class="main-feed" v-bind:class="[$root.lhspin ? 'lhspin' : '']">
    <div class="post">
      <h3>Ask your question</h3>
      <input class="title" type="text" v-model="post.title" />
      <h4>Add some tags to tell people what it is</h4>
      <div class="taglist">
        <span class="tag" v-for="(tag, i) in post.tags" :key="i">
          {{ tag }}
        </span>
        <input
          type="text"
          class="tags"
          v-model="newtag"
          @keydown.enter="addtag"
          @keydown.space="addtag"
        />
      </div>

      <div v-if="editor" class="wysiwyg">
        <button
          @click="editor.chain().focus().toggleBold().run()"
          :disabled="!editor.can().chain().focus().toggleBold().run()"
          :class="{ 'is-active': editor.isActive('bold') }"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <title>format-bold</title>
            <path
              d="M13.5,15.5H10V12.5H13.5A1.5,1.5 0 0,1 15,14A1.5,1.5 0 0,1 13.5,15.5M10,6.5H13A1.5,1.5 0 0,1 14.5,8A1.5,1.5 0 0,1 13,9.5H10M15.6,10.79C16.57,10.11 17.25,9 17.25,8C17.25,5.74 15.5,4 13.25,4H7V18H14.04C16.14,18 17.75,16.3 17.75,14.21C17.75,12.69 16.89,11.39 15.6,10.79Z"
            />
          </svg>
        </button>
        <button
          @click="editor.chain().focus().toggleItalic().run()"
          :disabled="!editor.can().chain().focus().toggleItalic().run()"
          :class="{ 'is-active': editor.isActive('italic') }"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <title>format-italic</title>
            <path d="M10,4V7H12.21L8.79,15H6V18H14V15H11.79L15.21,7H18V4H10Z" />
          </svg>
        </button>
        <button
          @click="editor.chain().focus().toggleStrike().run()"
          :disabled="!editor.can().chain().focus().toggleStrike().run()"
          :class="{ 'is-active': editor.isActive('strike') }"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <title>format-strikethrough-variant</title>
            <path
              d="M23,12V14H18.61C19.61,16.14 19.56,22 12.38,22C4.05,22.05 4.37,15.5 4.37,15.5L8.34,15.55C8.37,18.92 11.5,18.92 12.12,18.88C12.76,18.83 15.15,18.84 15.34,16.5C15.42,15.41 14.32,14.58 13.12,14H1V12H23M19.41,7.89L15.43,7.86C15.43,7.86 15.6,5.09 12.15,5.08C8.7,5.06 9,7.28 9,7.56C9.04,7.84 9.34,9.22 12,9.88H5.71C5.71,9.88 2.22,3.15 10.74,2C19.45,0.8 19.43,7.91 19.41,7.89Z"
            />
          </svg>
        </button>
        <button
          @click="editor.chain().focus().toggleCode().run()"
          :disabled="!editor.can().chain().focus().toggleCode().run()"
          :class="{ 'is-active': editor.isActive('code') }"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <title>code-tags</title>
            <path
              d="M14.6,16.6L19.2,12L14.6,7.4L16,6L22,12L16,18L14.6,16.6M9.4,16.6L4.8,12L9.4,7.4L8,6L2,12L8,18L9.4,16.6Z"
            />
          </svg>
        </button>
        <button @click="editor.chain().focus().unsetAllMarks().run()">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <title>eraser</title>
            <path
              d="M16.24,3.56L21.19,8.5C21.97,9.29 21.97,10.55 21.19,11.34L12,20.53C10.44,22.09 7.91,22.09 6.34,20.53L2.81,17C2.03,16.21 2.03,14.95 2.81,14.16L13.41,3.56C14.2,2.78 15.46,2.78 16.24,3.56M4.22,15.58L7.76,19.11C8.54,19.9 9.8,19.9 10.59,19.11L14.12,15.58L9.17,10.63L4.22,15.58Z"
            />
          </svg>
        </button>
        <button @click="editor.chain().focus().clearNodes().run()">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <title>eraser</title>
            <path
              d="M16.24,3.56L21.19,8.5C21.97,9.29 21.97,10.55 21.19,11.34L12,20.53C10.44,22.09 7.91,22.09 6.34,20.53L2.81,17C2.03,16.21 2.03,14.95 2.81,14.16L13.41,3.56C14.2,2.78 15.46,2.78 16.24,3.56M4.22,15.58L7.76,19.11C8.54,19.9 9.8,19.9 10.59,19.11L14.12,15.58L9.17,10.63L4.22,15.58Z"
            />
          </svg>
        </button>
        <button
          @click="editor.chain().focus().setParagraph().run()"
          :class="{ 'is-active': editor.isActive('paragraph') }"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <title>format-paragraph</title>
            <path
              d="M13,4A4,4 0 0,1 17,8A4,4 0 0,1 13,12H11V18H9V4H13M13,10A2,2 0 0,0 15,8A2,2 0 0,0 13,6H11V10H13Z"
            />
          </svg>
        </button>
        <button
          @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
          :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <title>format-header-1</title>
            <path
              d="M3,4H5V10H9V4H11V18H9V12H5V18H3V4M14,18V16H16V6.31L13.5,7.75V5.44L16,4H18V16H20V18H14Z"
            />
          </svg>
        </button>
        <button
          @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
          :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <title>format-header-2</title>
            <path
              d="M3,4H5V10H9V4H11V18H9V12H5V18H3V4M21,18H15A2,2 0 0,1 13,16C13,15.47 13.2,15 13.54,14.64L18.41,9.41C18.78,9.05 19,8.55 19,8A2,2 0 0,0 17,6A2,2 0 0,0 15,8H13A4,4 0 0,1 17,4A4,4 0 0,1 21,8C21,9.1 20.55,10.1 19.83,10.83L15,16H21V18Z"
            />
          </svg>
        </button>
        <button
          @click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
          :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <title>format-header-3</title>
            <path
              d="M3,4H5V10H9V4H11V18H9V12H5V18H3V4M15,4H19A2,2 0 0,1 21,6V16A2,2 0 0,1 19,18H15A2,2 0 0,1 13,16V15H15V16H19V12H15V10H19V6H15V7H13V6A2,2 0 0,1 15,4Z"
            />
          </svg>
        </button>

        <button
          @click="editor.chain().focus().toggleBulletList().run()"
          :class="{ 'is-active': editor.isActive('bulletList') }"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <title>format-list-bulleted</title>
            <path
              d="M7,5H21V7H7V5M7,13V11H21V13H7M4,4.5A1.5,1.5 0 0,1 5.5,6A1.5,1.5 0 0,1 4,7.5A1.5,1.5 0 0,1 2.5,6A1.5,1.5 0 0,1 4,4.5M4,10.5A1.5,1.5 0 0,1 5.5,12A1.5,1.5 0 0,1 4,13.5A1.5,1.5 0 0,1 2.5,12A1.5,1.5 0 0,1 4,10.5M7,19V17H21V19H7M4,16.5A1.5,1.5 0 0,1 5.5,18A1.5,1.5 0 0,1 4,19.5A1.5,1.5 0 0,1 2.5,18A1.5,1.5 0 0,1 4,16.5Z"
            />
          </svg>
        </button>
        <button
          @click="editor.chain().focus().toggleOrderedList().run()"
          :class="{ 'is-active': editor.isActive('orderedList') }"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <title>format-list-numbered</title>
            <path
              d="M7,13V11H21V13H7M7,19V17H21V19H7M7,7V5H21V7H7M3,8V5H2V4H4V8H3M2,17V16H5V20H2V19H4V18.5H3V17.5H4V17H2M4.25,10A0.75,0.75 0 0,1 5,10.75C5,10.95 4.92,11.14 4.79,11.27L3.12,13H5V14H2V13.08L4,11H2V10H4.25Z"
            />
          </svg>
        </button>
        <button
          @click="editor.chain().focus().toggleCodeBlock().run()"
          :class="{ 'is-active': editor.isActive('codeBlock') }"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <title>code-not-equal-variant</title>
            <path
              d="M11,6.5V9.33L8.33,12L11,14.67V17.5L5.5,12M13,6.43L18.57,12L13,17.57V14.74L15.74,12L13,9.26M5,3C3.89,3 3,3.9 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5A2,2 0 0,0 19,3H5Z"
            />
          </svg>
        </button>
        <button
          @click="editor.chain().focus().toggleBlockquote().run()"
          :class="{ 'is-active': editor.isActive('blockquote') }"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <title>comment-quote</title>
            <path
              d="M20 2H4C2.9 2 2 2.9 2 4V16C2 17.1 2.9 18 4 18H8V21C8 21.6 8.4 22 9 22H9.5C9.7 22 10 21.9 10.2 21.7L13.9 18H20C21.1 18 22 17.1 22 16V4C22 2.9 21.1 2 20 2M11 13H7V8.8L8.3 6H10.3L8.9 9H11V13M17 13H13V8.8L14.3 6H16.3L14.9 9H17V13Z"
            />
          </svg>
        </button>
        <button @click="editor.chain().focus().setHorizontalRule().run()">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <title>minus</title>
            <path d="M19,13H5V11H19V13Z" />
          </svg>
        </button>
      </div>
      <editor-content :editor="editor" />
<div style="text-align:right">
  <button class="txtbtn" @click="submitPost">Save</button>
</div>
      
    </div>
  </div>
</template>

<script>
import StarterKit from '@tiptap/starter-kit'
import { Editor, EditorContent } from '@tiptap/vue-3'
export default {
  name: 'NewPost',
  components: {
    EditorContent,
  },
  data() {
    return {
      editor: null,
      newtag: null,
      post: {
        title: '',
        html: '',
        tags: [],
      },
      cache : null
    }
  },
  methods: {
    addtag() {
      if (!this.post.tags.includes(this.newtag)) {
        this.post.tags.push(this.newtag)
      }
      this.newtag = null
    },
    submitPost() {
      var myHeaders = new Headers()
      myHeaders.append('x-access-tokens', this.$root.session.apitoken)
      myHeaders.append('Content-Type', 'application/json')

      let content = {}
      content.html = this.editor.getHTML()
      content.tags = this.post.tags

     
      
      var requestOptions = {}
      let url = ''
      let raw=''

      if (this.$route.params.id) {
         raw = JSON.stringify({
        title: this.post.title,
        content: JSON.stringify(content),
        posted_by: this.$root.session.userdata.user.id,
      })

        requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow',
        }
        url =
          'https://ou-test-project.fastpacedprojects.com/posts/' +
          this.$route.params.id
      } else {

         raw = JSON.stringify({
        title: this.post.title,
        content: JSON.stringify(content),
        posted_by: this.$root.session.userdata.user.id,
      })

        requestOptions = {
          method: 'PUT',
          headers: myHeaders,
          body: raw,
          redirect: 'follow',
        }

        url = 'https://ou-test-project.fastpacedprojects.com/posts'
      }

      fetch(url, requestOptions)
        .then((response) => response.text())
        .then((result) => {
          console.log(result)
          this.$router.push('/post/' + JSON.parse(result).id)
        })
        .catch((error) => console.log('error', error))
    },
  },
  mounted() {
    if (this.$route.params.id) {
      var myHeaders = new Headers()
      myHeaders.append('x-access-tokens', this.$root.session.apitoken)
      myHeaders.append("Content-Type", "application/json");
      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow',
      }
      fetch(
        'https://ou-test-project.fastpacedprojects.com/posts/' +
          this.$route.params.id,
        requestOptions,
      )
        .then((response) => response.text())
        .then((result) => {
          this.post.title = JSON.parse(result).title
          this.cache = JSON.parse(result)
          this.post.html = JSON.parse(JSON.parse(result).content).html
          this.post.tags = JSON.parse(JSON.parse(result).content).tags
          this.editor = new Editor({
            extensions: [StarterKit],
            content: this.post.html,
          })
        })
        .catch((error) => {
          console.log(error)
          this.loginError = true
        })
    } else {
      this.editor = new Editor({
        extensions: [StarterKit],
        content: '',
      })
    }
  },
  beforeUnmount() {
    this.editor.destroy()
  },
}
</script>

<style scoped>
.main-feed {
  position: absolute;
  top: 50px;
  left: 60px;
  bottom: 0px;
  right: 0px;
  overflow-y: scroll;
}

.lhspin {
  left: 300px;
}

.post {
  position: relative;
  border: 1px solid #ccc;
  padding: 10px 30px 30px 30px;
  border-radius: 5px;
  background-color: #fff;
  margin: 20px 20px 20px 20px;
}

.title {
  display: block;
  width: 100%;
  padding: 10px;
  font-size: 1.2rem;
}
</style>
