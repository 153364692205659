import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import PostView from '../views/PostView.vue'
import NewPostView from '../views/NewPostView.vue'
import EditPostView from '../views/EditPostView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/post/:id',
    name: 'post',
    component :PostView
  }
  ,
  {
    path: '/new',
    name: 'new',
    component : NewPostView
  },
  {
    path: '/edit/:id',
    name: 'edit',
    component :EditPostView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
