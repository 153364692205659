<template>
    <div id="HeaderBar" >

      <router-link to="/" class="toolbar-logo">
   
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12.89,3L14.85,3.4L11.11,21L9.15,20.6L12.89,3M19.59,12L16,8.41V5.58L22.42,12L16,18.41V15.58L19.59,12M1.58,12L8,5.58V8.41L4.41,12L8,15.58V18.41L1.58,12Z" /></svg>
       <span><strong>OU</strong>verflow</span>

      </router-link> 

      <button class="toolbar-button" @click="$root.logout()" style="float:right">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>account-off</title><path d="M12,4A4,4 0 0,1 16,8C16,9.95 14.6,11.58 12.75,11.93L8.07,7.25C8.42,5.4 10.05,4 12,4M12.28,14L18.28,20L20,21.72L18.73,23L15.73,20H4V18C4,16.16 6.5,14.61 9.87,14.14L2.78,7.05L4.05,5.78L12.28,14M20,18V19.18L15.14,14.32C18,14.93 20,16.35 20,18Z" /></svg>
      </button>


    </div>
  </template>
  
  <script>
  export default {
    name: 'ToolBar',
   
  }
  </script>
  
  
  <style scoped>
  #HeaderBar{
    position: absolute;
    top:0px;
    left:0px;
    right:0px;
    height: 50px;
    background-color: #7b1fa2;
  }
  .toolbar-logo{
    height:50px;

    background: none;
    border: none;
    fill:#fff;
    color: #fff;
    
    padding-left: 50px;
    font-size: 1.3rem;
    line-height: 45px;
    max-width: 200px;
    display: inline-block;
    text-decoration: none;
  }
  .toolbar-logo svg{
    height:35px;
    width: 35px;
    position: absolute;
    left:5px;
    top:5px;
    fill: inherit
  }

  
  .toolbar-button{
    width:50px;
    height:50px;
    background: none;
    border: none;
    color:#fff;
    fill:#fff;
    cursor: pointer;
  }
  .toolbar-button svg{
    fill: inherit;
    width:35px;
  }
  .toolbar-button:hover{
    background-color: #536dfe;
    fill: white;
  }
  </style>
  