<template>
  <div class="home">
 
  
    <MainFeed/>
    <SideBar />
    <ToolBar/>
  </div>
</template>

<script>
// @ is an alias to /src
import ToolBar from '@/components/ToolBar.vue'
import SideBar from '@/components/SideBar.vue'
import MainFeed from  '@/components/MainFeed.vue'

export default {
  name: 'HomeView',
  components: {
    ToolBar,
    SideBar,
    MainFeed
  }
}
</script>
