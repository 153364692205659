<template>
  <div class="menu" v-bind:class="$root.lhspin ? 'lhspinned' : ''">
    <button class="pinBtn" @click="$root.lhspin = !$root.lhspin">
      <svg
        style="width: 18px; height: 18px;"
        viewBox="0 0 24 24"
        v-if="$root.lhspin"
      >
        <path fill="#ffff" d="M16,12V4H17V2H7V4H8V12L6,14V16H11.2V22H12.8V16H18V14L16,12Z" />
      </svg>

      <svg
        style="width: 18px; height: px;"
        viewBox="0 0 24 24"
        v-if="!$root.lhspin"
      >
        <path fill="#ffff"
          d="M2,5.27L3.28,4L20,20.72L18.73,22L12.8,16.07V22H11.2V16H6V14L8,12V11.27L2,5.27M16,12L18,14V16H17.82L8,6.18V4H7V2H17V4H16V12Z"
        />
      </svg>
    </button>
    <div class="scrollpanel">
      <div class="profilecard">
        <div class="icon">
          <div class="placeholder">
            {{ getInitials($root.session.userdata.user.name) }}
          </div>
        </div>
        <div v-if="!editbio">
          <div class="name">
            {{ $root.session.userdata.user.name }}
          </div>
          <div class="bio">
            {{ $root.session.userdata.user.bio }}
          </div>
          <div style="text-align: right; margin-top:10px;">
            <button class="txtbtn" @click="editbio = true">Edit</button>
          </div>
          
        </div>
        <div v-if="editbio">

          <input
            class="inputs"
            type="text"
            v-model="$root.session.userdata.user.name"
          />
          <input
            class="inputs"
            type="email"
            v-model="$root.session.userdata.user.email"
          />
          <textarea
            rows="10"
            class="inputs"
            v-model="$root.session.userdata.user.bio"
          ></textarea>

          <div style="text-align: right; margin-top:10px;">
            <button class="txtbtn" @click="updateUser()">Save Changes</button>
          </div>
        
        </div>

        <div class="awards" style="text-align: center;">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <title>medal-outline</title>
            <path 
              d="M14.94 19.5L12 17.77L9.06 19.5L9.84 16.16L7.25 13.92L10.66 13.63L12 10.5L13.34 13.63L16.75 13.92L14.16 16.16M20 2H4V4L8.86 7.64A8 8 0 1 0 15.14 7.64L20 4M18 15A6 6 0 1 1 10.82 9.12A5.86 5.86 0 0 1 13.18 9.12A6 6 0 0 1 18 15M12.63 7H11.37L7.37 4H16.71Z"
            />
          </svg>

          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <title>owl</title>
            <path
              d="M12,16C12.56,16.84 13.31,17.53 14.2,18L12,20.2L9.8,18C10.69,17.53 11.45,16.84 12,16M17,11.2A2,2 0 0,0 15,13.2A2,2 0 0,0 17,15.2A2,2 0 0,0 19,13.2C19,12.09 18.1,11.2 17,11.2M7,11.2A2,2 0 0,0 5,13.2A2,2 0 0,0 7,15.2A2,2 0 0,0 9,13.2C9,12.09 8.1,11.2 7,11.2M17,8.7A4,4 0 0,1 21,12.7A4,4 0 0,1 17,16.7A4,4 0 0,1 13,12.7A4,4 0 0,1 17,8.7M7,8.7A4,4 0 0,1 11,12.7A4,4 0 0,1 7,16.7A4,4 0 0,1 3,12.7A4,4 0 0,1 7,8.7M2.24,1C4,4.7 2.73,7.46 1.55,10.2C1.19,11 1,11.83 1,12.7A6,6 0 0,0 7,18.7C7.21,18.69 7.42,18.68 7.63,18.65L10.59,21.61L12,23L13.41,21.61L16.37,18.65C16.58,18.68 16.79,18.69 17,18.7A6,6 0 0,0 23,12.7C23,11.83 22.81,11 22.45,10.2C21.27,7.46 20,4.7 21.76,1C19.12,3.06 15.36,4.69 12,4.7C8.64,4.69 4.88,3.06 2.24,1Z"
            />
          </svg>

          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <title>chat-alert-outline</title>
            <path
              d="M12 3C6.5 3 2 6.58 2 11C2.05 13.15 3.06 15.17 4.75 16.5C4.75 17.1 4.33 18.67 2 21C4.37 20.89 6.64 20 8.47 18.5C9.61 18.83 10.81 19 12 19C17.5 19 22 15.42 22 11S17.5 3 12 3M12 17C7.58 17 4 14.31 4 11S7.58 5 12 5 20 7.69 20 11 16.42 17 12 17M11 13V15H13V13H11M11 11H13V7H11V11Z"
            />
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SideBar',
  data() {
    return {
      drag: false,
      pinme: false,
      editbio: false,
    }
  },
  methods: {
    getInitials(str) {
      let a = str.split(' ')
      let output = ''
      a.forEach((element) => {
        output += element.charAt(0)
      })
      return output
    },
    updateUser() {
      var myHeaders = new Headers()
      myHeaders.append('x-access-tokens', this.$root.session.apitoken)
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        name: this.$root.session.userdata.user.name,
        email: this.$root.session.userdata.user.email,
        score: this.$root.session.userdata.user.score,
        bio: this.$root.session.userdata.user.bio,
        admin: this.$root.session.userdata.user.admin
      })

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow',
      }

      fetch(
        'https://ou-test-project.fastpacedprojects.com/users/'+this.$root.session.userdata.user.id,
        requestOptions,
      )
        .then((response) => response.text())
        .then((result) => console.log(result))
        .catch((error) => console.log('error', error))
        this.editbio = false
    },
  },
}
</script>

<style scoped>
.miniwordcount {
  font-weight: bold;
  text-align: right;
}
.itemBox {
  padding-right: 25px;
}
.settingsBtn {
  height: 40px;
  width: 100%;
  background-color: inherit;
  color: inherit;
  text-align: left;
  padding-left: 40px;
  cursor: pointer;
}
.settingsBtn svg {
  position: absolute;
  left: 5px;
  top: 5px;
}
.settingsBtn:hover,
.settingsBtn:focus,
.settingsBtn:active {
  background-color: var(--button-hover);
  color: var(--button-hover-f);
}
.settingsBtn:hover svg,
.settingsBtn:focus svg,
.settingsBtn:active svg {
  fill: var(--button-hover-f);
}
.pinBtn {
  position: absolute;
  top: 0px;
  right: 0px;
  border: 0px;
  background: none;
  padding: 5px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  background-color: var(--writer-side-nav);
}
.pinBtn svg {
  fill: var(--writer-side-nav-f);
}
.pinBtn:hover,
.pinBtn:active,
.pinBtn:focus {
  background-color: var(--button-hover);
  color: var(--button-hover-f);
}
.pinBtn:hover svg,
.pinBtn:active svg,
.pinBtn:focus svg {
  fill: var(--button-hover-f);
}
.button {
  margin-top: 35px;
}
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: var(--hover-ghost);
}
.list-group {
  min-height: 20px;
}
.list-group-item svg {
  fill: var(--writer-side-panels-f);
}
.menu {
  font-weight: 100;
  background: #424242;
  color: #fff;
  width: 300px;
  height: 100%;
  padding: 0px;
  padding-right: 40px;
  padding-top: 0px;
  position: fixed;
  top: 50px;
  -webkit-box-shadow: 3px 0px 5px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 3px 0px 5px 0px rgba(0, 0, 0, 0.2);
  left: -260px;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
}
.menu:hover,
.menu:focus,
.lhspinned {
  transform: translate3d(260px, 0, 0);
  animation-timing-function: 1s ease-in;
}
.scrollpanel {
  overflow-y: auto;
  position: absolute;
  top: 40px;
  bottom: 0px;
  width: 280px;
  padding: 0px;

  background-color: inherit;
}
.placeholder {
  height: 50px;
  width: 50px;
  border-radius: 10px;
  background-color: purple;
  color: white;
  font-size: 1.5rem;
  padding: 5px;
  margin: 5px;
  text-align: center;
  line-height: 40px;
  margin: 0 auto;
}
.name,
.icon {
  text-align: center;
  font-size: 1.3rem;
}
.awards svg {
  width: 30px;
  margin: 5px;
  fill: lightslategray;
}
.profilecard {
  padding: 20px;
}
</style>
