<template>
  <div class="main-feed" v-bind:class="[$root.lhspin ? 'lhspin' : '']">
    <div class="buttontools">
      <router-link class="btn" :to="'/new'">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <title>comment-plus</title>
          <path
            d="M9,22A1,1 0 0,1 8,21V18H4A2,2 0 0,1 2,16V4C2,2.89 2.9,2 4,2H20A2,2 0 0,1 22,4V16A2,2 0 0,1 20,18H13.9L10.2,21.71C10,21.9 9.75,22 9.5,22H9M11,6V9H8V11H11V14H13V11H16V9H13V6H11Z"
          />
        </svg>
      </router-link>
      <button class="btn" @click="showAll()">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <title>view-agenda-outline</title>
          <path
            d="M21 13H3A1 1 0 0 0 2 14V20A1 1 0 0 0 3 21H21A1 1 0 0 0 22 20V14A1 1 0 0 0 21 13M20 19H4V15H20M21 3H3A1 1 0 0 0 2 4V10A1 1 0 0 0 3 11H21A1 1 0 0 0 22 10V4A1 1 0 0 0 21 3M20 9H4V5H20Z"
          />
        </svg>
      </button>
      <button class="btn" @click="showLatest()">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <title>new-box</title>
          <path
            d="M20,4C21.11,4 22,4.89 22,6V18C22,19.11 21.11,20 20,20H4C2.89,20 2,19.11 2,18V6C2,4.89 2.89,4 4,4H20M8.5,15V9H7.25V12.5L4.75,9H3.5V15H4.75V11.5L7.3,15H8.5M13.5,10.26V9H9.5V15H13.5V13.75H11V12.64H13.5V11.38H11V10.26H13.5M20.5,14V9H19.25V13.5H18.13V10H16.88V13.5H15.75V9H14.5V14A1,1 0 0,0 15.5,15H19.5A1,1 0 0,0 20.5,14Z"
          />
        </svg>
      </button>
    </div>
    <div>
      <div class="post" v-for="(post, id) in qlist" :key="id">
        <UserWidget :userid="post.posted_by" />

        <h2 class="question">{{ post.title }}</h2>
        <div class="shorttext">
          {{ limitText(JSON.parse(post.content).html) }}
        </div>

        <div class="buttontools">
          <router-link class="btn" :to="'/post/' + post.id">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <title>eye-outline</title>
              <path
                d="M12,9A3,3 0 0,1 15,12A3,3 0 0,1 12,15A3,3 0 0,1 9,12A3,3 0 0,1 12,9M12,4.5C17,4.5 21.27,7.61 23,12C21.27,16.39 17,19.5 12,19.5C7,19.5 2.73,16.39 1,12C2.73,7.61 7,4.5 12,4.5M3.18,12C4.83,15.36 8.24,17.5 12,17.5C15.76,17.5 19.17,15.36 20.82,12C19.17,8.64 15.76,6.5 12,6.5C8.24,6.5 4.83,8.64 3.18,12Z"
              />
            </svg>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserWidget from '@/components/UserWidget.vue'
export default {
  name: 'HelloWorld',
  components: {
    UserWidget,
  },
  data() {
    return {
      qlist: [],
    }
  },
  methods: {
    limitText(txt) {
      let output = ''
      txt = txt.replace(/(<([^>]+)>)/gi, '')

      if (txt.length > 100) {
        output = txt.substring(0, 100) + '...'
      } else {
        output = txt
      }
      return output
    },
    showLatest() {
      var myHeaders = new Headers()
      myHeaders.append('x-access-tokens', this.$root.session.apitoken)

      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow',
      }

      fetch(
        'https://ou-test-project.fastpacedprojects.com/posts/latest/2',
        requestOptions,
      )
        .then((response) => response.text())
        .then((result) => {
          this.qlist = JSON.parse(result)
        })
        .catch((error) => {
          console.log(error)
          this.loginError = true
        })
    },
    showAll() {
      var myHeaders = new Headers()
      myHeaders.append('x-access-tokens', this.$root.session.apitoken)

      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow',
      }

      fetch(
        'https://ou-test-project.fastpacedprojects.com/posts',
        requestOptions,
      )
        .then((response) => response.text())
        .then((result) => {
          this.qlist = JSON.parse(result).reverse()
        })
        .catch((error) => {
          console.log(error)
          this.loginError = true
        })
    },
  },
  mounted() {
    this.showLatest()
  },
}
</script>

<style scoped>
.main-feed {
  position: absolute;
  top: 50px;
  left: 60px;
  bottom: 0px;
  right: 0px;
  overflow-y: scroll;
}

.post {
  position: relative;
  border: 1px solid #ccc;
  padding: 10px 30px 30px 30px;
  border-radius: 5px;
  background-color: #fff;
  margin: 20px 20px 20px 20px;
}
.lhspin {
  left: 300px;
}
</style>
