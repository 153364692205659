<template>
  <div v-if="userinfo" class="userwidget">
<table style="float:right">
    <tr>
        <td>
          <div class="placeholder">
        {{ getInitials(userinfo.user.name) }}
      </div>

        </td>
        <td>
            <div class="name">{{ userinfo.user.name  }}</div>
            <div class="email"><a :href="'mailto:' +userinfo.user.email  ">{{ userinfo.user.email  }}</a></div>
        </td>
    </tr>
</table>
<div style="clear:both"></div>
  </div>
</template>

<script>
export default {
  name: 'UserWidget',
  props: ['userid'],
  data() {
    return {
      userinfo: null,
    }
  },
  methods:{
    getInitials(str){
      let a= str.split(" ")
      let output =""
      a.forEach(element => {
        output += element.charAt(0)
      });

      return output
    }
  },
  mounted() {
    var myHeaders = new Headers()
    myHeaders.append('x-access-tokens', this.$root.session.apitoken)

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    }

    fetch(
      'https://ou-test-project.fastpacedprojects.com/users/'+this.userid,
      requestOptions,
    )
      .then((response) => response.text())
      .then((result) => {
        this.userinfo = JSON.parse(result)
      })
      .catch((error) => {
        console.log(error)
        this.loginError = true
      })




  },
}
</script>

<style scoped>
.userwidget{
  font-size: 12px;
  padding:5px;
  border-radius: 10px;
  display: block;
  position: relative;
  right:0px;
}

.placeholder{
    height:30px;
    width:30px;
    border-radius: 10px;
    background-color: #7B1FA2;
    color: white;
    font-size: 1rem;
    padding: 5px;
    margin:5px;
    text-align: center;
    line-height: 20px;
    margin: 0 auto;
  }
  .name{
    font-size: 1.3rem;
    margin-left: 10px;
  }
  .email a{
    margin-left: 10px;
  }
</style>
