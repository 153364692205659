<template>
  <div class="main-feed" v-bind:class="[$root.lhspin ? 'lhspin' : '']">
    <div class="post" v-if="post.posted_by">
      <router-link  :to="'/edit/' + post.id"
      v-if="post.posted_by == $root.session.userdata.user.id"
      >Edit</router-link>

      <h1>{{ post.title }}</h1>

      
      <div v-html="JSON.parse(post.content).html" class="ProseMirror"></div>
      <div class="tags">
        <div
          class="tag"
          v-for="(tag, i) in JSON.parse(post.content).tags"
          :key="i"
        >
          {{ tag }}
        </div>
      </div>
      <hr />
      <UserWidget :userid="post.posted_by" />
    </div>

    <div></div>
    <div v-for="(comment, id) in comments" :key="id" class="comment"  v-bind:class="[(this.post.correct_comment == comment.id) ? 'chosenanswer' : '']" >
      <UserWidget :userid="comment.posted_by" />
      <div v-if="editcomment === comment.id">
        <CommentEditor
          :postid="this.$route.params.id"
          :commentid="comment.id"
          @posted="closePostAndRefresh()"
        />
      </div>
      <div v-else>
        <div v-html="comment.content" class="ProseMirror"></div>
        <div style="text-align: right; font-size: 0.8rem; padding: 5px;">
          {{ comment.updated_at }}
        </div>
        <div
          class="buttontools"
          v-if="comment.posted_by == $root.session.userdata.user.id"
        >
          <button class="btn" @click="editcomment = comment.id">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <title>pencil</title>
              <path
                d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z"
              />
            </svg>
          </button>
        </div>
      </div>
      <button
        v-if="post.posted_by == $root.session.userdata.user.id && post.correct_comment != comment.id"
        @click="pickMe(comment.id)"
        class="txtbtn"
      >
      Mark as correct
      </button>
    </div>

    <div class="editcomment">
      Your Answer

      <CommentEditor
        :postid="this.$route.params.id"
        :commentid="null"
        @posted="closePostAndRefresh()"
      />
    </div>
  </div>
</template>

<script>
import UserWidget from '@/components/UserWidget.vue'
import CommentEditor from '@/components/CommentEditor.vue'

export default {
  name: 'PostFeed',
  components: {
    UserWidget,
    CommentEditor,
  },
  data() {
    return {
      editcomment: null,
      docomment: false,
      post: {},
      comments: [],
    }
  },
  methods: {
    closePostAndRefresh() {
      this.editcomment = null
      this.docomment = false
      this.getComments()
    },
    getPost() {
      var myHeaders = new Headers()
      myHeaders.append('x-access-tokens', this.$root.session.apitoken)
      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow',
      }
      fetch(
        'https://ou-test-project.fastpacedprojects.com/posts/' +
          this.$route.params.id,
        requestOptions,
      )
        .then((response) => response.text())
        .then((result) => {
          this.post = JSON.parse(result)
          this.getComments()
        })
        .catch((error) => {
          console.log(error)
          this.loginError = true
        })
    },
    getComments() {
      this.comments=[]
      var myHeaders = new Headers()
      myHeaders.append('x-access-tokens', this.$root.session.apitoken)
      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow',
      }
      fetch(
        'https://ou-test-project.fastpacedprojects.com/comments/post/' +
          this.$route.params.id,
        requestOptions,
      )
        .then((response) => response.text())
        .then((result) => {
          let sorted = JSON.parse(result)
          let holder = null

          if(this.post.correct_comment){
            sorted.every((element, ind) => {
              if(element.id == this.post.correct_comment){
                holder = JSON.parse(JSON.stringify(element))
                sorted.splice(ind, 1)
                return false
              }
              return true
          });
          // now insert it at beginning
          sorted.unshift(holder)
          }
          console.log(sorted)
         
        this.comments = sorted
        
          

        })
        .catch((error) => {
          console.log(error)
          this.loginError = true
        })
    },
    pickMe(chosen) {
      var myHeaders = new Headers()
      myHeaders.append('x-access-tokens', this.$root.session.apitoken)

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow',
      }

      fetch(
        'https://ou-test-project.fastpacedprojects.com/posts/'+this.post.id+'/correct/'+chosen,
        requestOptions,
      )
        .then((response) => response.text())
        .then((result) => {
          console.log(result)
          this.getPost()
        })
        .catch((error) => console.log('error', error))
    },
  },
  mounted() {
    this.getPost()
 
  },
}
</script>

<style scoped>
.main-feed {
  position: absolute;
  top: 50px;
  left: 60px;
  bottom: 0px;
  right: 0px;
  overflow-y: scroll;
}

.lhspin {
  left: 300px;
}

.post {
  border: 1px solid #ccc;
  padding: 10px 30px 30px 30px;
  border-radius: 5px;
  background-color: #fff;
  margin: 20px 20px 20px 20px;
}
.comment {
  border: 1px solid #ccc;
  padding: 10px 30px 30px 30px;
  border-radius: 5px;
  background-color: #eaeaea;
  color: #424242;
  margin: 20px 20px 20px 20px;
}

.editcomment {
  border: 1px solid #212121;
  padding: 5px;
  padding: 10px 30px 10px 30px;
  border-radius: 5px;
  background-color: #fff;
  margin: 20px 20px 20px 20px;
}
.chosenanswer{
  background-color: gold;

}
</style>
