<template>
  <div class="home">
 

    <NewPost/>  <SideBar />
    <ToolBar/>
  </div>
</template>

<script>
// @ is an alias to /src
import ToolBar from '@/components/ToolBar.vue'
import SideBar from '@/components/SideBar.vue'
import NewPost from  '@/components/NewPost.vue'

export default {
  name: 'NewPostView',
  components: {
    ToolBar,
    SideBar,
    NewPost
  }
}
</script>
