<template>
  <div v-if="!session.isauth">

    <div v-if="!register" class="login-form">
      <div class="logo">
        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M12.89,3L14.85,3.4L11.11,21L9.15,20.6L12.89,3M19.59,12L16,8.41V5.58L22.42,12L16,18.41V15.58L19.59,12M1.58,12L8,5.58V8.41L4.41,12L8,15.58V18.41L1.58,12Z"/>
        </svg>
        <span><strong>OU</strong>verflow</span>

      </div>
      <h1>Login</h1>
      <input v-model="session.email" name="email" placeholder="you@email.com" type="email"/>
      <input v-model="session.passWord" name="password" placeholder="password" type="password"/>
      <button class="loginButton" @click="authenticate()">Login</button>
      <button class="loginButton" @click="register=true">Register</button>
      <div v-if="loginError" class="loginalert">
        Check yor speling eejit - is case sensitive
      </div>
    </div>

    <div v-if="register" class="login-form">
      <div class="logo">
        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M12.89,3L14.85,3.4L11.11,21L9.15,20.6L12.89,3M19.59,12L16,8.41V5.58L22.42,12L16,18.41V15.58L19.59,12M1.58,12L8,5.58V8.41L4.41,12L8,15.58V18.41L1.58,12Z"/>
        </svg>
        <span><strong>OU</strong>verflow</span>

      </div>
      <h1>Register</h1>
      <input v-model="registerInfo.userName" name="username" placeholder="username" type="text"/>
      <input v-model="registerInfo.email" name="email" placeholder="you@email.com" type="email"/>
      <input v-model="registerInfo.passWord" name="password" placeholder="password" type="password"/>
      <input v-model="registerInfo.passWord2" name="password2" placeholder="password" type="password"/>
      <input v-model="registerInfo.registrationSecret" name="registrationSecret" placeholder="Registration code (ask Ed)"
             type="text"/>
      <hr/>
      <button class="loginButton" @click="register=false">Close</button>
      <button class="loginButton" @click="registerUser()">register</button>

      <div v-if="registerError" class="loginalert">
        {{ registerError }}
      </div>
    </div>
  </div>
  <div v-if="session.isauth">
    <router-view/>
  </div>
</template>

<script>
import '@/css/styles.css'

export default {
  name: 'WelcomePage',
  data() {
    return {
      lhspin: false,
      register: false,
      registerError: null,
      registerInfo: {
        userName: '',
        email: '',
        passWord: '',
        passWord2: '',
        bio: '',
        registrationSecret: '',
      },
      loginError: false,
      session: {
        apitoken: null,
        isauth: false,
        userName: null,
        passWord: null,
        userdata: {},
      },
    }
  },
  methods: {
    registerUser() {
      if (this.registerInfo.userName && this.registerInfo.email && this.registerInfo.passWord && this.registerInfo.registrationSecret) {
        if (this.registerInfo.passWord === this.registerInfo.passWord2) {
          // do the creation of the user then jump back to the auth screen and fill in username and password
          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");

          var raw = JSON.stringify({
            "name": this.registerInfo.userName,
            "password": this.registerInfo.passWord,
            "bio": this.registerInfo.bio,
            "email": this.registerInfo.email,
            "secret": this.registerInfo.registrationSecret
          });

          var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
          };

          fetch("https://ou-test-project.fastpacedprojects.com/register", requestOptions)
              .then(response => response.text())
              .then(result => {
                if (result.includes('incorrect')){
                  this.registerError = "Your registration secret was incorrect"
                } else{
                  console.log(result)
                  this.session.userName = this.registerInfo.userName
                  this.session.passWord = this.registerInfo.passWord

                  this.registerInfo = {
                    userName: '',
                    email: '',
                    passWord: '',
                    passWord2: '',
                    bio: '',
                  }
                  this.register = false
                }
              })
              .catch(error => console.log('error', error));


        } else {
          this.registerError = "Your Passwords do not match"
        }
      } else {
        this.registerError = "You've missed some info"
      }
    },
    logout() {
      this.session = {
        apitoken: null,
        isauth: false,
        userName: null,
        passWord: null,
        userdata: {},
      }
      localStorage.removeItem("OUverflow");
    },
    authenticate() {
      var myHeaders = new Headers()
      //myHeaders.append('Authorization', 'Basic RWR3aW46cGFzc3dvcmQ=')
      myHeaders.append(
          'Authorization',
          `Basic ${btoa(this.session.email + ':' + this.session.passWord)}`,
      )
      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow',
      }

      fetch(
          'https://ou-test-project.fastpacedprojects.com/login',
          requestOptions,
      )
          .then((response) => response.text())
          .then((result) => {
            this.session.apitoken = JSON.parse(result).token
            this.session.userid = this.parseJwt(this.session.apitoken).user_id
            console.log(this.session)
            this.getUserInfo()
          })
          .catch((error) => {
            console.log(error)
            this.loginError = true
          })
    },
    getUserInfo() {
      var myHeaders = new Headers()
      myHeaders.append('x-access-tokens', this.session.apitoken)

      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow',
      }

      fetch(
          'https://ou-test-project.fastpacedprojects.com/users/' +
          this.session.userid,
          requestOptions,
      )
          .then((response) => response.text())
          .then((result) => {
            this.session.userdata = JSON.parse(result)
            this.session.isauth = true
            localStorage.setItem('OUverflow', JSON.stringify(this.session))
          })

          .catch((error) => console.log('error', error))
    },
    parseJwt(token) {
      var base64Url = token.split('.')[1]
      var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
      var jsonPayload = decodeURIComponent(
          window
              .atob(base64)
              .split('')
              .map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
              })
              .join(''),
      )

      return JSON.parse(jsonPayload)
    },
  },
  mounted() {
    /*
    Stores some session stuff so people can leave and come back to the same page
    */
    let localdata = localStorage.getItem('OUverflow')
    if (localdata) {
      this.session = JSON.parse(localdata)
      console.log('load fron cache', this.session)
    }
  },
}
</script>

<style scoped>
.login-form {
  position: fixed;
  top: 0px;
  padding: 20px;
  border: 1px solid #ccc;
  max-width: 50%;
  min-width: 320px;
  border-radius: 5px;
  margin: 0 auto;
  margin-top: 20%;
  position: relative;
  background-color: purple;
  color: #fff;
  fill: #fff;
}

.login-form input {
  width: 100%;
  margin-bottom: 10px;
  padding: 10px;
}

.logo {
  position: relative;
  height: 50px;
  background: none;
  border: none;
  fill: inherit;
  color: inherit;
  cursor: pointer;
  padding-left: 50px;
  font-size: 1.3rem;
  line-height: 40px;
  width: 200px;
  margin: 0 auto;
}

.logo svg {
  height: 35px;
  width: 35px;
  position: absolute;
  left: 5px;
  top: 5px;
  fill: inherit
}

.loginalert {
  padding: 10px;
  text-align: center;
  color: #fff;
  background-color: red;
}

.loginButton {
  padding: 10px;
  background: none;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 5px;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>